



import { Component, Vue } from 'vue-property-decorator'

import AppVersionsTable from '@/components/AppVersionsTable'

Vue.component('AppVersionsTable', AppVersionsTable)

@Component
export default class AuditLog extends Vue {}
